.StatusDropdown {
    position: relative;
    padding: 0px !important;
}

.StatusDropdown .MuiFormControl-root {
    position: initial !important;
}

.StatusDropdown .MuiInput-root {
    position: initial !important;
}

.StatusDropdown .MuiSelect-icon {
    position: relative !important;

}

.StatusDropdown .MuiInput-underline:before {
    display: none;
}

.StatusDropdown .MuiInput-underline:after {
    display: none;
}

.StatusDropdown .StatusDropdownBody {
    border: #D2D3D8 0px solid;
    border-radius: 4px;
    padding: 0px 0px;

}

.StatusDropdown .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
}

.StatusDropdown .MuiSelect-root.MuiSelect-select {
    font-size: 14px;
    font-weight: 500;
    min-width: 180px;
    padding: 0 !important;
}

.StatusMenuList .StatusBackground {
    background: transparent !important;
}

.StatusMenuList .StatusLine {
    display: none !important;
}

.StatusBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 15%;
    left: 0;
    top: 0;
    border-radius: 6px;
}

.StatusInfo {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
}

.DropdownStatusTitle{
    font-size: 14px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
}

.StatusMenuList .DropdownStatusTitle {
    display: none;
    
}

.DropdownMenuListLabel {
    font-family: "Poppins", sans-serif;
}

.StatusDropdown .DropdownMenuListLabel {
    color: #001233 !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.StatusDropdown .MuiSelect-select{
    padding: 5px !important;
}

.StatusDropdown .MuiOutlinedInput-notchedOutline{
    display: none !important;
}