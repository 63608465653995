.Datefield {
    display: flex;
    flex-direction: column;
}

.Datefield .MuiFormControl-root {
    width: 100%;
}

.Datefield .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
    padding: 0px 14px !important;
}

.Datefield .MuiFormControl-root .MuiFormHelperText-root {
    color: #d32f2f;
    font-weight: 400;
}

.TableDateField svg {
    color: #5C677D;
    font-size: 22px;
}

.TableDateField input {
    color: #5C677D !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}