.HorizontalTab .MuiButtonBase-root.MuiTab-root {
    padding: 12px 16px !important;
    color: #5C677D !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: initial !important;
    letter-spacing: 0 !important;
}

.HorizontalTab .MuiTabs-indicator {
    background-color: #0450E1 !important;
}

.HorizontalTab .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #0450E1 !important;
}