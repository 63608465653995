.SnackbarMessage{
    position: fixed;
    z-index: 999999;    
}

.SnackbarMessage .MuiAlert-filledSuccess{
    background-color:  #0B9444 !important;
    box-shadow:  none !important;
    min-width: 500px;
}

@media (max-width: 500px) {
    .SnackbarMessage .MuiAlert-filledSuccess{
        min-width: 200px;
    }
}