.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 4px !important;
}

.FixedSizeTextArea .TextArea textarea {
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  font-size: 17px;
  min-height: 100px;
  padding: 2%;
  width: 100%;
  max-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

.BackButton {
  background: none;
  border: none;
  width: 0;
  cursor: pointer;
}

/* =========Sign in form ============= */

.SignContainer {
  max-width: 552px;
  margin: 0 auto;
  margin-top: 72px;
}

.SignContainerInner {
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.09);
  border-radius: 12px 12px 0 0;
  border: #E4E2E6 1px solid !important;
}

.SignContainerInner h1 {
  font-size: 24px;
  color: #1b1b1f;
  margin: 0;
  font-weight: 600;
  text-align: center;
}

.OrBox {
  border-bottom: #d4d6db 1px solid;
  position: relative;
  height: 5px;
  text-align: center;
}

.OrBox span {
  display: inline-block;
  background: #fff;
  padding: 0px 20px;
  position: relative;
  top: -9px;
  color: #676c7b;
  font-size: 14px;
}

.SocialButton img {
  width: 30px;
  position: absolute;
  left: 10px;
}

.SignContainer .ButtonContainer .MuiButtonBase-root {
  margin: 0 !important;
}

.AlreadyText {
  display: flex;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
}

.AlreadyText a {
  color: #004fe0 !important;
  margin: 0 !important;
  margin-left: 10px !important;
}

.TosText {
  text-align: center;
  color: #676c7b;
  font-size: 14px;
}

.TosText a {
  color: #676c7b !important;
  font-size: 14px !important;
  text-decoration: underline !important;
  font-weight: 400 !important;
}

.MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

.SignContainer :global .MuiOutlinedInput-notchedOutline legend {
  display: none !important;
}

.SignContainer :global .MuiOutlinedInput-input {
  padding: 0px 14px !important;
  height: 56px;
}

.SignContainer .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.SignFormFooter {
  background: #e4e8ec;
  height: 48px;
  width: 100%;
  left: 0;
  border-radius: 0 0 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SignFormFooter span {
  color: #5E5E62;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px;
}

.SignFormFooter svg {
  color: #5E5E62;
  font-size: 20px;
}

.ForgotPassword {
  color: #0450e1;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.DontHaveAccount {
  max-width: 360px;
  border-bottom: #c8c6ca 1px solid;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: -14px;
}

.DontHaveAccount span {
  display: inline-block;
  position: relative;
  background: #fff;
  padding: 5px 10px;
  top: 16px;
  font-size: 14px;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.Devider {
  height: 1px;
  background: #c8c6ca;
}

.FormDesText {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}

.FormDesText14 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
}

.FormHelpText {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #5e5e62;
}

.SignFormTopIcon {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.SignFormTopIcon img {
  max-width: 84px;
}

.RedendButtom {
  display: flex;
  justify-content: center;
}

.RedendButtom :global .MuiButtonBase-root {
  text-decoration: underline;
  text-transform: initial !important;
  padding: 0 !important;
  height: auto !important;
}

.AddField {
  border: #e4e2e6 1px solid;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.AddField .AddIcon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  background: #0450E1;
  border-radius: 100px;
  margin-right: 8px;
}

.AddField .MuiButtonBase-root {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  padding: 8px;
}

.AddFieldDisabled {
  border: #e4e2e6 1px solid;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddFieldDisabled .AddIcon {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b1b1f61;
  font-size: 20px;
  background: #1b1b1f1f;
  border-radius: 100px;
  margin-right: 8px;
}

.AddFieldDisableds .MuiButtonBase-root {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #000;
}

.UserCount {
  border-radius: 100px;
  padding: 5px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
  text-align: center;
}

.activeCount {
  background: #FFB606;
}

.inactiveCount {
  background: #f2f0f4;
}

.SignForm .MuiGrid-root.MuiGrid-container {
  margin-top: 0 !important;
}

.FormCancelButton {
  margin-top: 10px;
}

.FormCancelButton .MuiButtonBase-root {
  text-transform: initial !important;
  text-decoration: underline;
}

.InsertSection {
  display: flex;
  align-items: center;
}

.InsertSection a {
  color: #0450E1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.InsertSection a svg {
  margin-right: 8px;
}

.InsertSection span {
  display: inline-block;
  margin: 0 8px;
  color: #767680;
}















.CodeField .MuiInputBase-input {
  text-transform: uppercase !important;
}


@media (max-width: 768px) {
  .SignFormFooter {
    background: #e4e8ec;
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
  }

  .SignContainerInner {
    padding: 20px;
    padding-bottom: 150px;
  }
}

/* ---------------------------------------------------------------- */

.MuiOutlinedInput-notchedOutline {
  border: #c8c6ca 1px solid !important;
  border-radius: 8px !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border: #ba1a1a 1px solid !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

body {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Poppins", sans-serif;
}

h2 {
  font-family: "Poppins", sans-serif;
}

h3 {
  font-family: "Poppins", sans-serif;
}

/* ============= Password Strength Start ====== */

.PassStrengthBarContainer {
  padding-left: 16px;
  margin-right: 6px;
}

.PassStrengthBar {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 4px;
}

.PassStrengthBar .PassStrengthTitle {
  font-size: 12px;
  color: #676c7b;
  font-family: 'Poppins', sans-serif;
  margin-right: 8px;
}

.PassStrengthBar .Weak {
  font-size: 12px;
  color: #ba1a1a;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.PassStrengthBar .Good {
  font-size: 12px;
  color: #ffaa00;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.PassStrengthBar .Strong {
  font-size: 12px;
  color: #0b9444;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.StrengthBar {
  display: flex;
}

.StrengthBar span {
  background: #e9e9ee;
  height: 2px;
  display: block;
  flex: 1;
  border-radius: 2px;
  margin-right: 10px;
}

.passwordStrengthProgressBar .Weak .line_1 {
  background: #ba1a1a;
}

.passwordStrengthProgressBar .Good .line_1 {
  background: #ffaa00;
}

.passwordStrengthProgressBar .Good .line_2 {
  background: #ffaa00;
}

.passwordStrengthProgressBar .Strong .line_1 {
  background: #0b9444;
}

.passwordStrengthProgressBar .Strong .line_2 {
  background: #0b9444;
}

.passwordStrengthProgressBar .Strong .line_3 {
  background: #0b9444;
}

/* ============= Password Strength End ====== */

.FormSubTitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 127.273% */
  color: #1b1b1f;
}

.AccTitle {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #1B1B1F !important;
  margin-bottom: 8px !important;
}

.AccTitle span {
  color: #BA1A1A !important;
}

.AccTitleSelect {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #1B1B1F !important;
}

.AccTitleCont .MuiStack-root {
  padding-top: 0 !important;
}

.changeSettingcontainer {
  position: fixed;
  bottom: 0;
  background: #fff;
  border-top: #E8EAED 1px solid;
  padding: 16px 0 !important;
  width: -webkit-fill-available;
  margin-right: 24px;
}

.changeSettings {
  font-size: 14px;
  color: #1B1B1F;
}

.changeSettings a {
  font-size: 14px;
  color: #0450E1;
  text-decoration: none;
}

.GoogleField svg {
  opacity: 0;
  height: 48px;
  display: none;
}

.GoogleField .css-1okebmr-indicatorSeparator {
  display: none;
}

.EditDropdown .MuiList-root {
  min-width: 100px;
}

.GoogleField input {
  height: 33px;
}

.GoogleField div {
  border-radius: 8px !important;
}

.ExportOPtions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExportOPtions h1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #1B1B1F;
  margin: 0;
}

.CheckBoxGrouopContaienr {
  display: flex;
  flex-wrap: wrap;
}

.CheckBoxGrouopContaienr .CheckBox {
  width: 33%;
  text-align: left;
  padding-right: 15px;
}

.ExportOptions {
  padding-top: 20px;
}

.FieldLabel {
  color: #676C7B !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.MenuListContainer {
  display: block !important;
}

.MenuListContainer .MuiButtonBase-root.MuiMenuItem-root {
  padding: 8px 20px !important;
}

.Edituser.UploadPhotoFiled {
  border: #C6C6D0 1px solid;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  flex: 1;
}

.ActionMenu {
  position: relative;

}

.ActionMenu .ActionMenuButton {
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 2px 12px -5px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DropDownMenuCustom {
  position: absolute;
  z-index: 999;
  margin-top: 10px;
  right: 0;
  min-width: 160px;
}

.OddTabble .MuiTableContainer-root {
  overflow: initial !important;
}

.DropDownMenuCustom {
  background: #FFF;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.20);
  border-radius: 8px;
  padding: 6px 0;
}

.DropDownMenuCustom a {
  padding: 10px 16px;
  display: block;
  text-align: left;
  color: #1B1B1F;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.DropDownMenuCustom a:hover {
  background: #F8F8F8;
}

.UperCase .MuiInputBase-input {
  text-transform: capitalize !important;
}

.errorState {
  text-align: center;
  color: #d82a2a;
  font-size: 16px;
}

.errorStateOtp.OtpFields input {
  border: #d82a2a 1px solid !important;
  color: #d82a2a;
}

.UploadPhotoCard {
  display: flex;
}

.UploadPhotoCard {
  width: 100%;
  display: flex;
  align-items: center;
}

.UploadPhotoCard .UploadPhotoFiled {
  flex: 1;
}

.UploadPhotoCard .UploadPhotoCardImg {
  margin-right: 15px;
  border: #e6e6e6 1px solid;
  border-radius: 100px;
}

.backdropAlfa {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.CollapsForm .MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  height: 64px;
}

.AccIcon .defaultIcon {
  color: #E4E2E6;
  font-size: 28px;
}

.AccIcon .activeIcon {
  color: #66BD50;
  font-size: 24px;
}


.CollapsForm .AccLabel {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: #1B1B1F;
}

.CollapsForm .MuiAccordion-root.MuiAccordion-rounded {
  box-shadow: none !important;
  border: #e4e8ec 1px solid;
  min-height: 64px;
  border-radius: 12px !important;
  margin-bottom: 20px;
}

.CollapsForm .AccIcon {
  display: flex;
  align-items: center;
}

.CollapsForm .AccIcon .EditButton {
  color: #0450E1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding: 5px !important;
  min-width: 50px;
}

.CollapsForm .MuiAccordionSummary-root {
  padding: 0 24px !important;
  display: block !important;
}

.CollapsForm .MuiAccordionDetails-root {
  padding-bottom: 24px !important;
  padding: 0 26px 26px !important;
}

.AddFieldCustome {
  border: #e4e2e6 1px solid;
  border-radius: 12px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px 15px;
}

.AddFieldCustome.err {
  border: #BA1A1A 1px solid;
}

.err_text {
  color: #ba1a1a;
  margin-top: 6px;
}

.AddButtonMsg {
  display: flex;
  align-items: center;
}


.AddButtonMsg .AddIcon {
  background: #E4E2E6;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.AddButtonMsg .AddIcon svg {
  color: #fff;
}

.AddButtonMsg span {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.buttonArrow {
  display: flex;
}

.buttonArrow svg {
  font-size: 18px;
  color: #5E5E62;
}

.ButtonActive {
  background: #66BD50;
}

.ButtonActive .AddButtonMsg span {
  color: #fff;
}

.ButtonActive .buttonArrow svg {
  color: #fff;
}

.ButtonActive .AddButtonMsg .AddIcon {
  background: #fff;
}

.ButtonActive .AddButtonMsg .AddIcon svg {
  color: #66BD50;
}

.TextpastSection {
  display: flex;
  align-items: center;
}

.TextpastSection a {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.TextpastSection span {
  color: #767680;
  padding: 0 12px;
}

.P_Defaulf {
  color: #A8A8AA;
}

.P_Active {
  color: #0450E1;
}

.UplaodTextHelper {
  color: #767680;
  padding: 8px 0px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px
}

.ErrorAlert .MuiAlert-filledError.MuiAlert-filled {
  background: #F7E4E4 !important;
  color: #BA1A1A !important;
  border-radius: 8px !important;
  min-height: 50px;
}

.ByText {
  font-weight: 400 !important;
  color: #46464A !important;
  margin: 0 10px;
}

.FormFieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.FormFieldContainer.alignTop {
  align-items: flex-start;
}

.FormFieldContainer .FloatLabelCol {
  width: 35%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #1B1B1F !important;
}

.FormFieldContainer .FormFieldCol {
  width: 65%;
}

.PreviewSection {
  background: #F9FAFB;
  border: #c8c6ca 1px solid;
  height: 48px;
  border-radius: 8px;
  padding: 8px 16px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #77767A;
  display: flex;
  align-items: center;
}

.PreviewSection span {
  font-weight: 700;
  color: #1B1B1F;
}

.FormHeader {
  margin-bottom: 24px;
}

.FormHeader h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #1B1B1F;
  margin: 0;
}


.FormHeader p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #5E5E62;
  margin: 0;
  padding-top: 8px;
}

.MenuListContainer .MuiButtonBase-root.MuiMenuItem-root {
  text-transform: uppercase !important;
}

.RepeatDays {
  display: flex;
}

.RepeatDays span {
  display: flex;
  width: 40px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #E4E2E6;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #1B1B1F;
  margin: 0 6px;
}

.RepeatDays span:first-child {
  margin-left: 0;
}

.RepeatDays .ActiveDay {
  background: #0450E1;
  color: #fff;
}

.StudentDetailsContainer .BlueSection {
  background: #0450E1;
  height: 72px;
}

.StudentDetailsContainer .P_imgSection {
  display: flex;
  align-items: center;
  margin-top: -40px;
  padding-left: 24px;
}

.StudentDetailsContainer .P_imgSection .MuiAvatar-root {
  width: 146px;
  height: 146px;
  font-size: 50px;
}

.P_imgSectionInfo {
  margin-left: 22px;
  padding-top: 20px;
}

.StudentDetailsContainer .P_imgSectionInfo h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  color: #000;
  margin: 0;
  display: flex;
  align-items: center;
}

.StudentDetailsContainer .P_imgSectionInfo h1 svg {
  font-size: 12px;
  color: #66BD50;
  margin-left: 8px;

}


.StudentDetailsContainer .P_imgSectionInfoSub {
  display: flex;
  align-items: center;
}

.StudentDetailsContainer .P_imgSectionInfoSub p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #5E5E62;
  margin: 0;
}

.StudentDetailsContainer .P_imgSectionInfoSub span {
  background: #C6C6D0;
  height: 12px;
  width: 1px;
  margin: 0 8px;
}

.AttendanceStatus {
  border: #E4E2E6 1px solid;
  border-radius: 12px;
  display: flex;
}

.AttendanceStatus li {
  display: flex;
  flex: 1;
  padding: 20px;
  border-right: #E4E8EC 1px solid;
}

.AttendanceStatus li:last-child {
  border: none;
}

.AttendanceStatus li .AttendanceStatusIcon {
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AttendanceStatus li .AttendanceStatusInfo {
  margin-left: 16px;
}

.AttendanceStatus li .AttendanceStatusInfo h2 {
  margin: 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  color: #0B132B;
  line-height: 26px;
}

.AttendanceStatus li .AttendanceStatusInfo p {
  margin: 0;
  color: #676C7B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.AttendanceStatus li .AttendanceStatusIcon.green {
  background: #EDF7EA;
  color: #66BD50;
}

.AttendanceStatus li .AttendanceStatusIcon.red {
  background: #F7E4E4;
  color: #BA1A1A;
}

.AttendanceStatus li .AttendanceStatusIcon.yellow {
  background: #FFEEC3;
  color: #FFB606;
}

.GreenIcon {
  color: #66BD50;
}

.RedIcon {
  color: #BA1A1A;
}

.YellowIcon {
  color: #FFB606;
}

.AdtStatus {
  display: flex;
  align-items: center;
}

.AdtStatus svg {
  margin-right: 4px;
}

.StudentAdtnTable .TableContainer.DrawerTable {
  border: #E4E2E6 1px solid !important;
  padding-top: 8px;
  margin-top: 24px;
}

.H_Tab_c {
  margin-top: 15px;
}

.H_Tab_c .MuiTabs-indicator {
  background-color: #0450E1 !important;
}

.H_Tab_c .MuiButtonBase-root.MuiTab-root {
  color: #46464A;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  min-width: auto !important;
  padding: 0 20px !important;
}

.H_Tab_c .MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #0450E1 !important;
}

.ArchiveSection {
  border-top: #F2F0F4 1px solid;
  border-bottom: #F2F0F4 1px solid;
  padding: 32px 0;
  margin-top: 32px;
}

.SwitchField {
  border: #E4E2E6 1px solid;
  border-radius: 6px;
  padding: 0px 16px;
  padding-right: 0;
}

.FieldComingSoon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px !important;
  background: #F9FAFB;
}

.FieldComingSoon h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #1B1B1F;
}

.FieldComingSoon span {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #5E5E62;
  background: #E4E2E6;
  border-radius: 6px;
  padding: 4px 10px;
}

.DetailsTabsCont .MuiBox-root {
  padding: 0 !important;
}

.DetailsTabs {
  margin-top: 0 !important;
}

.DndCard {
  margin-bottom: 12px;
}

.ModalSingleField .TextField .FieldCount {
  font-size: 14px;
  position: absolute;
  right: 13px;
  top: 45px;
}

.OuthFormBottomText {
  text-align: center;
  padding: 10px;
}

.OuthFormBottomText span {
  font-size: 12px;
  color: #5E5E62;
}

.OuthFormBottomText {
  color: #0450E1;
}

.PhoneField {
  display: flex;
}

.PhoneField .CustomePhoneInput {
  top: 0 !important;
  height: 40px !important;
  border-right: none !important;
  border-radius: 8px 0 0 8px !important;
  border: #E8EAED 1px solid;
  padding: 15px;
  text-indent: 20px;
  width: 100%;
}

.PhoneField .ButtonContainer button {
  border-radius: 0px 8px 8px 0px !important;
}

.Rec_buttons {
  display: flex;
}

.Rec_buttons .ButtonContainer {
  margin-left: 10px;
}

.RecordedChip {
  background: #D1EBCF;
  padding: 4px;
  color: #001233;
  border-radius: 6px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-right: 10px;

}

.RecordedChip span {
  width: 4px;
  height: 100%;
  border-radius: 100px;
  background: #0b9444;
  display: block;
  margin-right: 4px;
}

.AudioListenContainer {
  text-align: center;
}

.AudioListenContainer audio {
  width: 100%;
  margin-bottom: 32px;
}

.PhoneHelperText {
  color: #77767A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.inputError .PhoneHelperText {
  color: #BA1A1A;
}

/* .inputError .PhoneField .CustomePhoneInput {
  border: #BA1A1A 1px solid;
} */

.PhoneFieldInput {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.PhoneFieldInput span {
  position: absolute;
  left: 10px;
}

.DateFieldContainer .MuiInputBase-root {
  height: 48px !important;
}

.Datefield .MuiInputBase-root {
  height: 48px !important;
}