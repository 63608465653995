.ComingSoon {
    background: #fff;
    border-radius: 12px;
    padding: 56px;
    text-align: center;

}

.ComingSoon img {
    width: 262px;
}

.ComingsoonCont h1 {
    color: #1B1B1F;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 700;
}

.ComingSoon h3 {
    color: #1B1B1F;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    margin-top: 30px;
    margin-bottom: 0;
}

.ComingSoon p {
    color: #5E5E62;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    max-width: 485px;
    margin: 0 auto;
    padding-top: 8px;
}