.ProspectDetails {
  display: flex;
  justify-content: space-between;
  border-bottom: #E8EAED 1px solid;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.ProspectDetails .ProspectDetailsLeft {
  display: flex;
  align-items: center;
}

.ProspectDetails .ProspectDetailsLeft .MuiAvatar-root {
  display: flex;
  height: 96px;
  width: 96px;
  padding: 16px 25px 16px 26px;
  justify-content: center;
  align-items: center;
  background-color: #0450E1;
  font-size: 57px;
  font-family: "Poppins", sans-serif;
}

.ProspectDetails .ProspectDetailsLeft .ProspectDetailsText {
  margin-left: 16px;
}

.ProspectDetails .ProspectDetailsLeft .ProspectDetailsText h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: #001233;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.ProspectDetails .ProspectDetailsLeft .ProspectDetailsText p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #5C677D;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.ProspectPersonainfo {
  padding: 24px 0;
}

.ProspectPersonainfo li {
  display: flex;
  margin-bottom: 24px;
}

.ProspectPersonainfo li p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5C677D;
  margin: 0;
  padding-right: 74px;
  width: 200px;
}

.ProspectPersonainfo li h2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #001233;
  margin: 0;
}

.ActivityTaleContainer .TableHeaderSection h1{
font-size: 20px !important;
}

.ActivityTaleContainer .TableContainer .MuiTableCell-root {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ActivityTaleContainer .TableCell span{
  font-size: 12px !important;
}

.ActivityTaleContainer .TableFooter .MuiTablePagination-toolbar {
  padding: 14px 0 !important;
  border-top: #E8EAED 1px solid;
}