.FieldPasswordC ::placeholder {
  color: #ccc;
  font-size: 16px;
}

.FieldPassword .customInput {
  width: 100%;
  height: 93%;
  color: #676c7b;
  font-size: 16px;
  border: transparent;
  position: relative;
  top: 1px;
}

.FieldPassword .customInput:focus {
  outline: none !important;
}

.FieldPassword {
  position: relative;
}

.FieldPassword .iconSpan svg {
  color: #303034;
}

.trackerBox {
  padding-top: 15px;
  margin-left: 16px;
}

.not-validated {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #676c7b !important;
}

.validated {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  text-decoration: line-through;
  color: #acaaaf !important;
}

.not-validated svg {
  color: #676c7b;
  font-size: 16px;
}

.validated svg {
  color: #4c9f41;
  font-size: 16px;
}

.trackerBox {
  color: #676c7b;
  font-size: 14px;
}

.green {
  color: #4c9f41;
}

.trackerBox div {
  display: flex;
}

.trackerBox div svg {
  color: #676c7b;
  font-size: 16px;
  margin-right: 10px;
}

.PasswordStrengthBar {
  margin-left: 20px;
  margin-bottom: 22px;
}

.PasswordStrengthBar p {
  float: left;
  margin-top: 0 !important;
}

.PasswordStrengthBar div {
  position: relative;
  top: 15px;
  margin-bottom: 4px;
}

.PasswordFieldCont {
  position: relative;
  height: 48px;
  border-radius: 8px;
  border: #e4e8ec 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.PassIcon {
  cursor: pointer;
  height: 26px;
}

@media (max-width: 1600px) {
  .FieldPassword .customInput {
    height: 42px;
  }

  .FieldPassword .iconSpan {
    top: 10px;
  }
}