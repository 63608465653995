@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.Inner-Page-Container {}

/*== Header Css == */
.Inner-Page-Container .Inner-Page-Header {
    padding: 0px 12px;
    border-bottom: #E8EAED 1px solid;
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    align-items: center;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Left-Col {
    display: flex;
    align-items: center;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Back-Arrow {
    width: 24px !important;
    height: 24px !important;
    min-width: initial !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    border-radius: 0 !important;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Back-Arrow svg {
    color: #5C677D;
    font-size: 24px;
    display: inline-block;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs {
    margin-left: 12px;

}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol {
    display: flex;
    align-items: center;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol li{
    margin-right:10px;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #5C677D;
    text-decoration: none !important;
    font-family: "Poppins", sans-serif;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol a:hover {
    text-decoration: none !important;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol span{
    display: flex;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-ol a svg {
    width: 18px;
    margin-right: 4px;
    color: #5C677D;
}


.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .Bread-Crumbs-Link {
    background-color: transparent !important;
    color: #5C677D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .MuiChip-label {
    padding: 0 4px !important;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .Bread-Crumbs-Link:focus {
    box-shadow: none !important;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .Bread-Crumbs-Link:active {
    box-shadow: none !important;
    opacity: 1 !important;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .Bread-Crumbs-Link svg {
    color: #5C677D !important;
}

.Inner-Page-Container .Inner-Page-Header .Bread-Crumbs .Bread-Crumbs-Link i {
    color: #5C677D !important;
    font-size: 18px !important;
}

.Inner-Page-Container .Inner-Page-Header .MuiBreadcrumbs-separator {
    margin-left: 0;
    margin-right: 0;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Menu .MuiButtonBase-root svg {
    color: #5C677D !important;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Right-Col {
    display: flex;
    align-items: center;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Buttons {
    display: flex;
    align-items: center;
}

.Inner-Page-Container .Inner-Page-Header .Inner-Page-Header-Buttons .ButtonContainer {
    margin-right: 12px;
}

.Inner-Page-body {
    display: flex;
}

.Inner-Page-body .Inner-Page-Side-Bar {
    width: 300px;
}

.Inner-Page-body .Inner-Page-Side-Bar .Inner-Page-Side-Bar-body {
    position: absolute;
    width: 250px;
    height: 100%;
    left: 0;
    background: #F8F9FB;
    border-right: #E6EAF0 1px solid;
}

.Inner-Page-Content {
    width: 100%;
    flex: 1;
    padding: 24px 156px;
}