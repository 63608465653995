/* ====== Sing in css ==========*/

.SignContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    padding: 0 25px;
}

.SignFormGrid{
    padding: 0 25px;
}


.SignContainerInner {
    width: 552px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 0px 32px #00000014;
    border-radius: 28px;
    position: relative;
}

.SignContainerInner h1 {
    font-size: 24px;
    color: #0B132B;
    margin: 0;
    font-weight: 600;
    text-align: center;
}

.SignContainerInner p{
    font-size: 14px;
    color: #0B132B;
    text-align: center;
    margin: 0;
}

.SignForm {
    margin: 0 auto;
    padding: 32px 20px;
    padding-bottom: 20px;
}

.HideLabelClass .FieldLabel{
    display: none;
}

.SignFormFooter {
    background: #E4E8EC;
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 0 0 28px 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SignFormFooter span {
    color: #676C7B;
    font-size: 14px;
    font-weight: 400;
    margin: 0 10px;
}

.SignFormFooter svg {
    color: #676C7B;
    font-size: 20px;
}


.OrBox {
    border-bottom: #d4d6db 1px solid;
    position: relative;
    height: 5px;
    text-align: center;
}

.OrBox span {
    display: inline-block;
    background: #fff;
    padding: 0px 20px;
    position: relative;
    top: -9px;
    color: #676C7B;
    font-size: 14px;
}

.SocialButton img{
    width: 30px;
    position: absolute;
    left: 10px;
}

.SignContainer .ButtonContainer .MuiButtonBase-root{
    margin: 0 auto !important;
}

.AlreadyText{
    display: flex;
    color: #676C7B;
    font-size: 14px;
    justify-content: center;
}

.AlreadyText a{
    color: #004FE0 !important;
    margin: 0 !important;
    margin-left: 10px !important;
}

.TosText{
    text-align: center;
    color: #676C7B;
    font-size: 14px;
}

.TosText a{
    color: #676C7B !important;
    font-size: 14px !important;
    text-decoration: underline !important;
    font-weight: 400 !important;
}

.OtpField{
    justify-content: center;
    margin-top: 24px;
}

.OtpField input{
    border:#D2D3D8 1px solid;
    width: 52px !important;
    height: 52px !important;
    border-radius: 8px;
    margin-right: 4px !important;
    font-size: 22px;
}

.OtpField input:nth-child(4) { 
    margin-left:15px;
    margin-right: 15px;
}


.ResendButton {
    justify-content: center !important;
    flex-wrap: wrap !important;
}

.ResendButton span {
    display: block;
    width: 100%;
    color: #616570;
    font-size: 16px;
}

.ResendButton button {
    background: #fff !important;
    box-shadow: none !important;
    height: 48px !important;
    border-radius: 8px !important;
    font-weight: 600;
    text-transform: initial !important;
    font-size: 14px;
    padding: 0 20px;
    outline: none !important;
    border: none;
    color: #004FE0 !important;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
}

.OrgLogo{
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.OrgLogo img{
    height: 64px;
}

.Enrolling{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
}
.Enrolling p{
    font-size: 22px;
    color: #676C7B;
    margin-bottom: 0;
}

.Enrolling h1{
    font-size: 34px;
    color: #0B132B;
    padding-top: 78px;
    max-width: 744px;
    margin: 0 auto;
}

.Enrolling h2{
    font-size: 20px;
    color: #676C7B;
    font-style: italic;
    font-weight: 500;
   
}

.Enrolling .MuiCircularProgress-colorPrimary{
    color: #004FE0 !important;
    width: 64px !important;
    height: 64px !important;
}

.FormHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.BackButton {
    background: none;
    border: none;
    width: 0;
    cursor: pointer;
}

.BottomButton{
    margin-top: 15px;
}

@media (max-width: 500px) {
    .SignForm {
        padding: 20px;
    }

    .SignContainerInner {
        width: 100%;
    }

    .SignContainerInner h1 {
        font-size: 16px;
    }


    .SignFormFooter.Iftwo svg {
        color: #676C7B;
        font-size: 15px;
    }

    .SignContainer {
        margin-top: 32px;
        padding: 0 11px;
    }

    .SignFormGrid {
        padding: 0 0px;
    }

    .Iftwo.SignFormFooter span {
        color: #676C7B;
        font-size: 11px;
    }

}

@media (max-width: 460px) {
    .OtpField input {
        width: 38px !important;
        font-size: 20px;
        height: 45px !important;
    }
}

@media (max-width: 360px) {
    .OtpField input {
        width: 34px !important;
    }
}

@media (max-width: 330px) {
    .OtpField input {
        width: 32px !important;
    }
}