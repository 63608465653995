.EmptyCard {
    background: #fff;
    padding: 56px;
    text-align: center;
}

.EmptyCard h1 {
    margin: 0px;
    font-size: 24px;
    color: #1B1B1F;
}

.EmptyCard p {
    margin: 8px;
    font-size: 16px;
    color: #5E5E62;
}

.EmptyCardIcon {
    width: 200px;
}

.EmptyCardButton {
    display: flex;
    justify-content: center;
}

.EmptyCardContainer .TableHeader .TableTitle {
    color: #0B132B;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    margin-bottom: 20px;
}