.TableSubHeader {
    display: flex;
    justify-content: space-between;
}

.TableSubHeader .Tb_hr_R_Section {
    display: flex;
    align-items: center;
}

.TableSubHeader .Tbl_Actions {
    display: flex;
    align-items: center;
}

.TableSubHeader .Tbl_Actions .MuiButtonBase-root.MuiButton-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif !important;
    color: #5C677D !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    text-transform: initial !important;
}

.TableSubHeader .Tbl_Actions svg {
    font-size: 20px;
    margin-right: 4px;
}

.HorizontalTaleTabPanel .MuiBox-root {
    padding: 0 !important;
    margin: 0 !important;
}

.TableSubHeader .HorizontalTab {
    position: relative;
}

.BorderBottom {
    width: 100%;
    height: 1px;
    background: #E6EAF0;
}

.HorizontalTaleTabPanel .Table .MuiTableHead-root .MuiTableCell-head {
    border-top: #fff 0px solid !important;
}