.TableHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.TableHeaderSection h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    font-family: 'Source Sans 3', sans-serif !important;
    color: #001233;
    margin: 0;
}

.TableHeaderSection h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Source Sans 3', sans-serif !important;
    color: #001233;
    margin: 0;
}

.TableHeaderDropdownListing {
    text-transform: uppercase !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #5C677D !important;
}