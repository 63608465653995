.MenuIconOpen{
    position: relative;
    top: 100px;
}

.MenuIconClose {
    position: absolute;
    top: 7px;
    right: -69px;
    z-index: 99999;
    background: #ffffff;
    border-radius: 0 8px 8px 0;
    border: #eaeaea 1px solid;
}

.MenuIconClose svg{
    color: #000;
}




