.SearchBox {
  position: relative;
  border: #77767A 1px solid !important;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchBoxField {
  border: none;
  outline: none;
  height: 38px;
  border-radius: 8px;
  width: 90%;
  padding: 0 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Source Sans 3', sans-serif !important;
}

.SearchBoxButton {
  cursor: pointer;
  background: #77767A;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SearchBoxButton svg {
  color: #fff;
  font-size: 22px;
}