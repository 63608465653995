@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.TableContainer {
  background: #fff;
  border-radius: 12px;
  box-shadow: none !important;
  border: none;
  position: relative;
}

.TableContainer .MuiTableCell-root {
  border-bottom: #E8EAED 1px solid !important;
  padding: 6px 20px !important;
}

.TableContainer.BorderTable {
  box-shadow: none !important;
  border: none !important;
}

.TableContainer.CondensedTable .MuiTableCell-root {
  padding: 12px 20px;
}

.TableContainer .MuiPaper-elevation1 {
  box-shadow: none !important;
  /* margin-bottom: 5px !important; */
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0fa711 !important;
}


.TableContainer .tablePage .MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  padding-left: 0;
  text-align: left;
  padding-top: 10px;
}

.TableHeaderMainContainer {
  border-bottom: #E8EAED 1px solid;
  padding-bottom: 16px !important;
}

.TableInnerHeader {
  background: #fff;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TableInnerHeader h3 {
  color: #0b132b;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

.TableContainer .MuiTablePagination-root {
  border-radius: 0 0 12px 12px;
}

.TableCell {
  font-family: "Poppins", sans-serif !important;
  ;
}

.TableCell span {
  font-family: "Poppins", sans-serif !important;
  ;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.CellCont {
  display: flex;
  align-items: center;
}

.TableCell .CellCont .CellImg {
  display: flex;
  align-items: center;
}

.TableCell .CellCont .MuiAvatar-root {
  margin-right: 12px;
  padding: 0px;
  width: 28px;
  height: 28px;
  border-radius: 8px !important;
}

.TableCell .CellCont .textString {
  font-size: 14px;
  color: #1b1b1f;
}

.TableCell .CellCont .textBold {
  font-weight: 600 !important;
  display: flex;
}

.TableCell .subtitle {
  color: #676c7b;
  font-size: 14px !important;
  display: block;
  text-decoration: underline;
}

.TableCell .icon img {
  width: 40px;
  margin-right: 16px;
}

.status {
  display: flex;
  width: fit-content;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
}

.rounded {
  border-radius: 6px;
}

.rounde {
  border-radius: 6px;
}

.success {
  color: #66bd50;
  background: #edf7ea;
}

.warning {
  color: #ffaa00;
  background: #ffe7b6;
}

.error {
  color: #ff0033;
  background: #ffe0e6;
}

.inprogress {
  color: #004fe0;
  background: #e0eafb;
}

.inprogress-color {
  color: #004fe0;
}

.bold {
  font-weight: 600;
}

.roundedTwo {
  border-radius: 8px;
}

.default {
  color: #000;
  background: #e9e9e9;
}


.Table .MuiTableCell-root:last-child {
  text-align: end;
  padding: 0;
}

.Table .TableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Table {
  border-radius: 10px;
  width: 100%;
}

.Table .TableTitle {
  color: #001233;
  font-size: 28px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  ;
  margin: 0px;
  line-height: 40px;
}

.Table .button {
  display: flex;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  text-decoration: none;
  justify-content: center;
  width: 102px;
}

.Table .ActionButton {
  display: flex;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 8px;
  text-decoration: none;
  justify-content: center;
  color: #004fe0;
  background: #e0eafb;
}

.Table .MuiTableCell-root h1 {
  color: #0b132b;
  font-size: 14px;
  margin: 0;
}

.Table .MuiTableCell-root p {
  margin: 0;
}

.Table .MuiTableCell-root a {
  color: #676c7b;
  font-size: 14px;
  width: max-content !important;
}

.Table .TableImgCell .MuiAvatar-root {
  margin-right: 18px;
}

.Table .TableImgCell {
  display: flex;
}

.Dark {
  color: #676c7b;
}

.Table .MuiTableHead-root {
  background: #ffffff !important;
}

.Table .MuiTableHead-root .MuiTableCell-head {
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  color: #979DAC !important;
  padding: 14px;
  padding-left: 20px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  background: #ffffff !important;
}

.MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
  ;
}

.MuiTableBody-root {
  overflow-x: auto !important;
}

.Tablechip {
  border-radius: 100px !important;
  padding: 3px 8px;
  margin: 0 8px;
  background: #dbece3;
  color: #0b9444;
  font-size: 10px;
  font-weight: 600;
}

.tablelink {
  max-width: 305px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tablelink a {
  color: #004FE0 !important;
  text-decoration: none;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
}

.textDate {
  font-size: 14px;
  color: #676c7b;
}

.tableIcon {
  margin-right: 15px;
}

.tableIcon a {
  background: #e4e8ec;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableIcon a svg {
  font-size: 24px;
}

.ActionButtons {
  float: right;
}

.ActionButtons .MuiButtonBase-root {
  background: rgb(255, 255, 255);
  color: rgb(0, 79, 224);
  height: 40px;
  font-size: 14px;
  border: 1px solid rgb(228, 232, 236);
  width: auto;
  border-radius: 8px !important;
  padding: 0 8px 0px 16px !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiMenu-paper {
  border: #e4e8ec 1px solid;
  margin-top: 2px;
}

.MuiMenu-paper .MuiListItem-root {
  padding: 5px 30px !important;
  cursor: pointer;
}

.MuiMenu-paper .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.TableInnerHeaderLeft {
  display: flex;
  align-items: center;
}

.TableInnerHeaderRight {
  display: flex;
  align-items: center;
}

.TableInnerHeaderRight .FieldLabel {
  display: none;
}

.TableInnerHeaderRight .ButtonContainer {
  margin-left: 15px;
}

.TableInnerHeaderRight .MuiSelect-select {
  min-width: 134px;
}

.TableInnerHeaderRight .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
  height: 48px;
}

.TableInnerHeaderRight .TextField {
  position: relative;
  top: 3px;
}


.TableSearchField .SearchBox {
  width: 336px;
}

.TableCell .textLightLink {
  display: block;
  text-decoration: underline;
  font-size: 14px;
  color: #676c7b;
}

.FontMedium {
  font-weight: 500 !important;
}

.TableInfo {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.TableLabel {
  color: #5C677D !important;
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.TableNodate {
  color: #919094 !important;
  font-weight: 400 !important;
}

.oddEvenTable .MuiTableCell-root {
  border: none !important;
  padding: 10px 16px !important;
}

.TableRedio .MuiRadio-colorSecondary.Mui-checked {
  color: #004fe0;
}

.TableRedio .MuiRadio-colorSecondary {
  padding-left: 0;
}

.FilterTitle {
  font-size: 14px;
  color: #0b132b;
  font-weight: 600;
  border-bottom: #e4e8ec 1px solid;
  margin-bottom: 20px;
  padding-bottom: 16px;
}

.FilterContainer {
  position: relative;
}

.filterBody {
  width: 264px;
  padding: 15px;
}

.FilterButtonGroup {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.FilterButtonGroup .ButtonContainer {
  margin-left: 10px;
}

.FilterCheckBox p {
  margin-bottom: 0;
}

.FilterCheckBox .MuiFormGroup-root {
  display: flex !important;
  flex-direction: initial !important;
  flex-wrap: wrap;
}

.FilterButton {
  border: #e4e8ec 1px solid !important;
  height: 48px !important;
  color: #004fe0 !important;
  border-radius: 8px !important;
  text-transform: initial !important;
  padding: 0 20px !important;
}

.FilterContainer .MuiPaper-root.MuiPaper-elevation {
  border-radius: 12px !important;
}

/* .MuiButtonBase-root .MuiTableSortLabel-icon {
  display: none;
} */

.statussmall {
  display: flex;
  min-width: 63px;
  height: 24px;
  align-items: center;
  font-size: 14px;
}

.TableCell .TableCheckbox {
  position: relative;
  left: -11px;
}

.Table .TableInnerHeaderRight .MuiToolbar-root.MuiToolbar-regular {
  position: absolute;
  width: 68%;
  left: 22%;
  top: 66px;
  padding: 0px 20px;
  border-radius: 8px 8px 0 0;
  height: 81px !important;
}

.TableSelectFiled .SelectFiled .MuiInputBase-root.MuiOutlinedInput-root {
  height: 28px !important;
  width: 96px !important;
  background: #e4e2e6 !important;
  border-radius: 4px !important;
}

.TableSelectFiled .MuiSelect-select.MuiSelect-outlined {
  padding: 0 14px !important;
}

.TableAddButtonContainer {
  display: flex;
  align-items: center;
}

.TableAddButtonContainer .ButtonContainer {
  margin-left: 8px;
}

.TableSelectFiled .SelectFiled .MuiOutlinedInput-notchedOutline {
  border: #c8c6ca 1px solid !important;
  border-radius: 4px !important;
}

.AttendanceTableContainer {
  background: #f2f0f4;
  border-right: #E8EAED 1px solid;
}

.AttendanceTableContainer .MuiTableRow-root.MuiTableRow-head {
  border-top: #E8EAED 1px solid;
}

.AttendanceTable .MuiTableCell-root {
  border-bottom: #E8EAED 1px solid !important;
  border-left: #E8EAED 1px solid !important;
  padding: 6px 16px !important;
}

.AttendanceTable .MuiTableCell-root:first-child {
  border-left: #E8EAED 0px solid !important;
}

.AttendanceTable .MuiTablePagination-toolbar {
  border-top: #E8EAED 1px solid;
}

Table .MuiTablePagination-toolbar {
  border-top: #E8EAED 1px solid;
}

.AttendanceTable .MuiTableRow-root {
  height: auto !important;
}

.AttendanceTable .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: #E8EAED 0px solid !important;
}

.AttendanceTable .MuiTableRow-root.MuiTableRow-head .MuiTableCell-root.MuiTableCell-head {
  border-bottom: #E8EAED 1px solid !important;
}

.AttendanceTable .TableContainer {
  overflow: hidden;
}

.AttendanceTableDate {
  /* width: 32.26%; */
  width: 36.17%;
  display: inline-table;
  border-bottom: #C6C6D0 1px solid;
  display: flex;
  align-items: center;
}

.AttendanceTableDateIn {
  padding: 0 0;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  color: #1b1b1f;
  text-align: left;
}

.AttendanceTableContainer .MuiTableCell-root.MuiTableCell-head {
  background: transparent !important;
}



.WeekSldier {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 0;
}

.WeekSldier li {
  flex: 1;
  display: block;
  text-align: center;
}

.WeekSldier li span {
  display: block;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  ;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #5C677D;
}

.WeekSldier li label {
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  ;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  display: flex !important;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.DayActicve label {
  background: #004fe0;
  color: #fff;
  border-radius: 100px;
}

.DayActicve span {
  color: #004fe0;
}

.TableCarosal .MuiMobileStepper-root {
  background: transparent !important;
}

.CarosalArrowLeft {
  position: relative !important;
  top: -45px;
  left: -20px;
}

.CarosalArrowRight {
  position: relative !important;
  top: -45px;
  right: -15px;
}

.WeekSldierContainer {
  width: 70%;
  padding-top: 15px;
  height: 48px;
  border-left: #C6C6D0 1px solid;
}

.WeekSldierContainer .MuiMobileStepper-root {
  padding: 4px !important;
  width: 99% !important;
}

.TableCarosal .MuiButton-root.Mui-disabled {
  color: #cbcbcb !important;
}

.AtndTblContainer .MuiTableHead-root .MuiTableCell-head {
  background: transparent;
  color: #1B1B1F !important;
  border-right: #E8EAED 1px solid;
}

.AtndTblContainer .MuiTableHead-root .MuiTableCell-head:last-child {
  border-right: #E8EAED 0px solid;
}

.TableContainer .MuiTableHead-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #10a711;
}

.TableCheckbox .Mui-checked .MuiIconButton-label {
  color: #0450E1 !important;
}

.tableDeleteModalButton {
  background: #fff !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #ba1a1a !important;
  margin: 9px 0 !important;
}

.TableHeaderContainer .MuiToolbar-regular {
  min-height: auto !important;
}

.TableHeaderContainer .MuiToolbar-root.MuiToolbar-regular .MuiTypography-root {
  color: #ba1a1a;
  font-weight: 400;
}

.TableHeaderContainer .MuiToolbar-root.MuiToolbar-regular {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.tableButtons .ButtonContainer .MuiButtonBase-root {
  text-transform: uppercase;
}

.TableMainTitle {
  color: #0b132b;
  font-size: 34px;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  ;
  margin: 0px;
  margin-bottom: 24px;
}

.alightRight {
  text-align: right !important;
  display: flex;
  align-items: center;
}

.alightRight .ButtonContainer {
  display: inline-block;
}

.AttendanceTableContainerMain {
  position: relative;
}

.ProfileTable .MuiTableContainer-root {
  overflow-x: visible !important;
}

.FilterButtonDrawer .ButtonContainer .MuiButtonBase-root {
  text-transform: initial !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.AttendanceTableHead {
  display: flex;
  justify-content: space-between;
  background: #F2F0F4;
}


.WeekSldierContainer .TableCarosal .MuiButtonBase-root {
  padding: 0 !important;
  color: #004fe0;
}

.WeekSldierContainer .TableCarosal .MuiButtonBase-root:hover {
  padding: 0 !important;
}

.SearchResult {
  margin-right: 18px;
  font-weight: 600;
  font-size: 14px;
  color: #77767A;
}

.TableDateField {
  display: flex;
  border-radius: 8px;
  max-width: 300px;
  align-items: center;
}

.TableDateField .DateChangeArrowArrowContainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}


.TableDateField .DateChangeArrow .arrow {
  color: #5C677D !important;
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto !important;
}

.TableDateField .DateChangeArrowArrowContainer svg {
  font-size: 16px;
}

.TableDateField .MuiOutlinedInput-notchedOutline {
  display: none !important;
}

.TableDateField .Datefield .MuiButtonBase-root {
  position: absolute;
  left: 4px;
}

.TableDateField .Datefield .MuiInputBase-input {
  margin-left: 30px;
}

.TableStatusIcon {
  border-radius: 100px;
  width: 18px;
  height: 18px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #66BD50;
  max-width: 18px;
  max-height: 18px;
}

.TableStatusIcon svg {
  font-size: 100%;
  color: #fff;
}


@media (max-width: 1268px) {
  .AttendanceTableDate {
    width: 31.6%;
  }
}

@media (max-width: 768px) {
  .TableInnerHeader {
    display: block;
  }

  .PeronalInfocard {
    display: block;
    text-align: center;
  }

  .PeronalInfocardDetail {
    display: block;
  }

  .PeronalInfocard .UserIcon {
    margin-right: 0;
    text-align: center;
    display: inline-block;
  }

  .TableSearchField .MuiInputBase-root {
    width: 100%;
  }

  .MuiTableRow-root.MuiTableRow-head .MuiTableCell-root.MuiTableCell-head {
    min-width: 150px !important;
  }

  .CellCont {
    min-width: fit-content;
  }

  .TableInnerHeaderLeft {
    display: block;
  }

  .TableInnerHeaderRight .ButtonContainer {
    margin-left: 0;
    width: 100%;
  }

  .TableInnerHeaderRight .ButtonContainer .MuiButtonBase-root {
    border-radius: 12px !important;
    padding: 0 20px;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .TableInnerHeader h3 {
    margin-bottom: 9px;
  }

  .TableInnerHeader .MuiFormControl-root {
    width: 97.5% !important;
  }
}

.TableContainer .MuiTableBody-root .MuiTableRow-root.Mui-selected {
  background-color: #EFF0FF !important;
}

.UserNamePhoto {
  display: flex;
  align-items: center;
}

.UserNamePhoto .MuiAvatar-circular {
  margin-right: 8px;
  width: 28px;
  height: 28px;
}

.AddedChip {
  display: flex;
  align-items: center;
  background: #66BD50;
  padding: 4px 6px;
  color: #fff;
  border-radius: 4px;
  max-width: fit-content;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.AddedChip svg {
  color: #fff;
  font-size: 20px;
  margin-right: 4px;
}

.SortIcon {
  font-size: 15px !important;
  color: #959595;
  position: relative;
  top: 4px;
  margin-left: 5px;
}

.yes .SortIcon {
  transform: rotate(180deg);
  opacity: 1 !important;
}

.sort .SortIcon {
  transform: rotate(360deg);
  opacity: 1 !important;
}

.MuiTableSortLabel-icon {
  opacity: 0 !important;
  display: none !important;
}

.TextWithLinkText {
  margin-left: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #0450E1 !important;
}



/* * ======================================= Course Attendance Table Css Start ==================================== * */
.studentTableCellContainer.MuiTableCell-root {
  display: flex;
  gap: 8px;
  align-items: center;
}

.studentTableCellContainer.MuiTableCell-root p {
  display: flex;
  flex-direction: column;
}

.studentTableCellContainer.MuiTableCell-root p b {
  font-size: 14px;
}

.studentTableCellContainer.MuiTableCell-root p span {
  font-size: 12px;
}

.MuiPaper-elevation8 {
  box-shadow: 0 0 2px lightgray !important;
}

.AttendanceMenuItem {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.AttendanceColorBox {
  height: 16px;
  width: 16px;
  border-radius: 4px;
}

.AttendanceMenuItem p {
  margin: 0;
  display: inline-block;
}

.status .StatusLine {
  width: 4px;
  height: 16px;
  border-radius: 100px;
  margin-right: 4px;
}

/* ===  Table Footer New Css ==*/

.TableFooter .MuiSelect-select.MuiTablePagination-select {
  display: none;
}

.TableFooter .MuiSvgIcon-root.MuiSelect-icon {
  display: none;
}

.TableFooter .MuiTablePagination-actions .MuiButtonBase-root {
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  border: #0450E1 1px solid !important;
  border-radius: 8px !important;
  padding: 6px 12px !important;
  margin-left: 8px;
  color: #0450E1;
  height: 28px !important;
  font-family: "Poppins", sans-serif !important;
}

.TableFooter .MuiTablePagination-actions .MuiButtonBase-root.Mui-disabled {
  border: #E8EAED 1px solid !important;
  color: #BBBFC9;
  height: 28px !important;
}




.TableFooter .MuiTablePagination-actions .MuiButtonBase-root svg {
  display: none;
}

.TableFooter .MuiTablePagination-actions .MuiButtonBase-root:first-child::after {
  content: "Previous";
}

.TableFooter .MuiTablePagination-actions .MuiButtonBase-root:last-child::after {
  content: "Next";
}

.TableFooter .MuiTablePagination-toolbar {
  min-height: auto;
  padding-right: 2px;
  padding: 14px 0;
}

.Table .MuiToolbar-root {
  display: block !important;
}

.Table .MuiToolbar-root .MuiTypography-colorInherit {
  color: inherit;
  float: left;
}

.Table .MuiToolbar-root .MuiTablePagination-actions {
  float: right;
}

.Table .MuiToolbar-root .MuiTablePagination-displayedRows {
  color: #5C677D;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: "Poppins", sans-serif !important;
  margin-right: 8px;
  display: inline-block;
}

.Table .MuiTablePagination-input {
  display: none !important;
}

.Table .RowNumber {
  font-weight: 700 !important;
}

.Table .MuiTablePagination-selectLabel {
  display: inline-block;
  margin-right: 8px;
}

.Table .PaginationResultLabel {
  font-weight: 400 !important;
  color: #5C677D;
  font-size: 14px;
}

.ActionButtonsDotIcon svg {
  color: #5C677D !important;
}

.MuiTablePagination-root {
  padding-bottom: 20px !important;
}

.BoxTable .TableInnerHeader {
  padding: 16px 18px !important;
}

.TableActionMenu {
  color: #5C677D !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;

}

.OddTableContainer {
  border-radius: 16px;
  border: 1px solid #E8EAED;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.TableInfo a {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #004FE0;
  text-decoration: none;
}


.TableInfo p {
  color: #0B132B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 2px !important;
}

.TableInfo .VerifyCheckIcon {
  display: flex;
}

.TableInfo .VerifyCheckIcon svg {
  color: #0B9444;
  margin-left: 8px;
}

.TableLinkText {
  display: flex;
  align-items: center;
}

.TableverifiedIcon {
  display: flex;
  background: #E1F2E8;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-left: 12px;
}

.TableverifiedIcon svg{
  color: #0B9444;
  font-size: 18px;

}

.MuiList-root a{
  text-decoration: none !important;
}