.Inner-Page-Side-Bar-Tabs {
    display: flex;
    flex: 1;
}

.Inner-Page-Side-Bar-Tabs .MuiTabs-scroller {
    white-space: normal !important;
    padding-top: 15px;
}

.Inner-Page-Side-Bar-Tabs .MuiTabs-flexContainer {
    display: block;
}


.Inner-Page-Side-Bar-Tabs .MuiTabs-indicator {
    display: none;
}

.Inner-Page-Side-Bar-Tabs .MuiTouchRipple-root{
    display: none !important;
}


.Inner-Page-Side-Bar-Tabs .MuiButtonBase-root .LayoutTabLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #001233 !important;
    text-transform: initial !important;
    padding: 10px !important;
    text-align: left;
    min-width: 200px !important;
    border-radius: 8px !important;
    font-family: "Poppins", sans-serif;
}

.Inner-Page-Side-Bar-Tabs .MuiButtonBase-root .LayoutTabLabel svg{
   margin-right: 10px;
   font-size: 20px;
}

.Inner-Page-Side-Bar-Tabs .Mui-selected .LayoutTabLabel  {
    background: #E5EBF7;
    color: #004FE0 !important;
    min-width: 164px;
}

.Inner-Page-Side-Bar-Pannel{
    flex: 1;
}

.Inner-Page-Side-Bar-Tabs .MuiTab-root{
    padding: 8px 16px !important;
}





















