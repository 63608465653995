.StatusContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.StatusContainer .StatusLeftCol .BackLink {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #5C677D !important;
    text-decoration: none !important;
    text-transform: initial !important;
    height: 32px !important;
}

.StatusContainer .StatusLeftCol .BackLink i {
    font-size: 18px;
    margin-right: 4px;
}

.StatusContainer .StatusLeftCol h1 {
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    color: #001233 !important;
    text-decoration: none !important;
    margin: 0;
}

.StatusRightCol {
    display: flex;
    max-width: 60%;
    justify-content: end;
}

.StatusRightCol li {
    display: block;
    margin: 0 3px;
    flex: 1;
}