.TextField {
    position: relative;
}

.TextField .CharAlignMid {
    position: relative;
}

.TextField .CharAlignMid .MuiInputBase-input {
    padding-right: 60px;
    padding-left: 16px !important;
}

.TextField .CharAlignMid .CharInner {
    position: absolute;
    right: 12px;
}

.TextField .CharAlignMid .FieldCount {
    position: absolute;
    top: 16px;
}

.TextField .CharAlignMid {
    display: flex;
    align-items: center;
}

.TextField .CharAlignMid .CHAR {
    display: none;
}

.TextField .Uppercase .MuiInputBase-input {
    text-transform: uppercase !important;
}

.TextField .MuiInputBase-root.MuiOutlinedInput-root {
    height: 48px !important;
}

.TextField .MuiOutlinedInput-notchedOutline {
    top: 0 !important;
    height: 48px !important;
}



.TextField .FieldCount {
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: -21px;
}