.StatusStaticContainer {
    padding: 6px;
    width: 186px;
    max-width: 180px;
    border-radius: 6px;
    height: 68px;
    display: flex;
    flex: 1 1;
    align-items: center;
    position: relative;
}


.StatusStaticContainer h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #7D8597;
    margin: 0 !important;
    line-height: 2;
}

.StatusStaticContainer h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #001233;
    margin: 0 !important;
    line-height: 2;
}

.StatusStaticBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    opacity: 15%;
}

.StatusStaticLine {
    width: 6px;
    height: 100%;
    border-radius: 100px;
    margin-right: 6px;
}