.ChipStatusContainer {
    padding: 6px 8px;
    border-radius: 6px;
    height: 32px;
    display: flex;
    flex: 1 1;
    align-items: center;
    position: relative;
}


.ChipStatusContainer h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #7D8597;
    margin: 0 !important;
    line-height: 2;
    font-family: "Poppins", sans-serif !important;
}

.ChipStatusContainer h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #001233;
    margin: 0 !important;
    line-height: 2;
    font-family: "Poppins", sans-serif !important;
}

.ChipStatusBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    opacity: 15%;
}

.ChipStatusLine {
    width: 6px;
    height: 100%;
    border-radius: 100px;
    margin-right: 6px;
}