.HeaderContainer {
  position: relative;
  top: 0;
  z-index: 999;
  width: 100%;
  left: 0;
}

.Header {
  background: #fff;
  height: 65px;
  width: auto;
  padding: 0px 24px;
  border-bottom: #c6c6d0 1px solid;
  display: flex;
  justify-content: space-between;
}

.Header .Branding img {
  height: 35px;
}

.HdrRightCol {
  display: flex;
  align-items: center;
  gap: 12px;
}

.Header .HdrRightCol .MuiAvatar-root {
  margin-left: 16px !important;
  border-radius: 50%;
  cursor: pointer;
}

.NotificationButton {
  border: #d2d3d8 1px solid !important;
  border-radius: 10px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: initial !important;
  min-height: initial !important;
  position: relative;
}

.NotificationButton svg {
  color: #0b132b;
}

.NotificationButton .NotificationCount {
  background: #e63946;
  text-align: center;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  right: -10px;
  top: -7px;
}

/* === Dropwdown menue == */

.DropdownMenu .MuiPopover-paper {
  width: 352px;
  margin-left: -20px;
  box-shadow: 0px 2px 17px #00000017 !important;
  margin-top: 38px;
}

.BorderLeft {
  margin: 12px 0;
  border-left: 2px solid lightgray;
}

.ProfileSection {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ProfileSection h1 {
  font-size: 16px;
  color: #0b132b;
  margin: 0;
  line-height: 20px;
}

.ProfileSection p {
  margin: 0;
}

.ProfileSection p a {
  font-size: 12px;
  color: #676c7b;
  text-decoration: none;
}

.ProfileSection .MuiAvatar-root {
  margin-right: 8px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.DropdownMenuList {
}

.DropdownMenu .DropdownMenuList .MuiListItem-root {
  padding: 15px 25px !important;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}

.DropdownMenu .DropdownMenuList .MuiListItem-root .MuiSvgIcon-root {
  margin-right: 12px;
}

.IconMenu {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

/* .IconMenu{display: none;} */

.HdrLeftCol {
  display: flex;
  align-items: center;
}

.MobileView {
  display: none;
}

/* ============= Sticku header smooth ====== */
.sticky {
  position: fixed;
  width: 100%;
  top: 0px;
  transition: all 0.5s ease;
  animation: smoothScroll 0.5s forwards;
  z-index: 999;
  height: auto;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* ============= Sticku header smooth ====== */

@media (max-width: 990px) {
  .DesktopView {
    display: none;
  }

  .MobileView {
    display: block;
  }

  .BrowsButton {
    text-align: center;
  }

  .BrowsButton .ButtonContainer {
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .BrowsButton .MuiButtonBase-root {
    width: 90%;
    margin: 0 !important;
  }
}

@media (max-width: 990px) {
  .HeaderContainer {
    z-index: 999;
  }
}
