.SelectFiled .MuiInputBase-root.MuiOutlinedInput-root {
    height: 49px !important;
    width: 100% !important;
    background: #fff;
    border-radius: 12px;
}

.SelectFiled .MuiSelect-nativeInput {
    height: 49px;
}

.SelectFiled .MuiSelect-select {
    padding-left: 15px !important;
}

textarea {
    font-family: 'Source Sans 3', sans-serif !important;
}

.SelectFiled .MuiOutlinedInput-notchedOutline legend span {
    opacity: 0 !important;
}

.SelectFiled .MuiFormLabel-root {
    font-family: 'Source Sans 3', sans-serif !important;
    font-size: 18px !important;
    color: #ACAAAF !important;
    margin-bottom: 4px !important;
    font-weight: 400 !important;
    /* padding: 0px 5px !important; */
    top: -3px;

}

.SelectFiled .MuiInputLabel-shrink.MuiInputLabel-sizeMedium {
    display: none;
}

.SelectFiled .MuiInputLabel-shrink {
    background: #fff;
    margin-top: 3px;
}

.UserTypeSelect .SelectFiled .MuiSelect-select {
    padding-left: 15px !important;
    padding-top: 29px !important;
    height: 35px !important;
}

.UserTypeSelect .SelectFiled .MuiSelect-nativeInput {
    height: 35px;
}

.SelectFieldAddNew {
    background: #F8F8F8;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: #0450E1;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    bottom: -10px;
    text-transform: uppercase;

}

.SelectFieldAddNew svg {
    font-size: 20px;
    margin-right: 4px;
}