.HeaderTwo {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.HeaderTwo a img {
    width: 68px;
}

.FormHeaHeaderTwoder .HeaderTwoR span {
    color: #676C7B;
    font-size: 14px;
    margin-right: 10px;
}

.HeaderTwo .HeaderTwoR a {
    color: #0B132B;
    font-size: 14px;
}

@media (max-width: 500px) {
    .HeaderTwo {
        display: flex;
        justify-content: center;
        padding: 25px;
        padding-bottom: 0;
    }
}

